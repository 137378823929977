import React, { FunctionComponent } from 'react'
import { Spinner } from 'react-bootstrap';

interface SearchBoxProps {
  searchBarInput: string;
  isSearching: boolean;
  updateSearch: (value: string) => void;
}
const SearchBox: FunctionComponent<SearchBoxProps> = ({searchBarInput, isSearching, updateSearch}) => {
  return (
    <div>
      <input 
        id='main_search-box'
        type='text'
        value={searchBarInput}
        onChange={(e) => updateSearch(e.target.value)}
      />
      {isSearching && <Spinner animation='grow'/>}
    </div>
  )
}

export default SearchBox