import React, { FunctionComponent } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai';
interface IAdminListProps {
  items: any[],
  setId: (id: string) => void,
  setCurrentPage: (page: string) => void,
  deleteEntry: (id: string) => void;
}
const AdminList: FunctionComponent<IAdminListProps> = ({items, setId, setCurrentPage, deleteEntry}) => {
  return (
    <div>{items?.map((item: any) => {
      return (<div>
        <span 
          className='item-link' 
          onClick={() => {setId(item.id); setCurrentPage('admin-details')}}
        >{item.id}</span>
        <span onClick = {() => deleteEntry(item.id)}><AiFillCloseCircle /></span></div>)
    })}</div>
  )
}

export default AdminList