import React from 'react'

const OriginStory = () => {
  return (
    <div className='origin-story'>
      <h2>What's our why? How did we get here?</h2>
      <div>
        &emsp;Out of control, unaccountable... Let's cut the 
        crap, brazen police brutality. That's where this all 
        began. Others came before us, paving the way forward. 
        Social change comes slowly, with public awareness,
        shining a spotlight on supposed "purveyors of 
        justice". Some injustices have been addressed, but 
        we've only just begun. The fact is that the corruption 
        in our court system runs rampant, as many courts are 
        no longer using court reporters, nor recording public 
        trials. This has been under the guise of cost savings, 
        but the simple fact is that it's a means of covering 
        up this corruption. We know that body camera's on 
        police have solved a lot of cases of abuse of power 
        by those enlisted to serve and protect, and has 
        prevented countless deaths. So why are we going the 
        opposite direction in courtrooms?
      </div>
      <div>
        &emsp;That's where this chapter begins. A courtroom. There 
        hasn't been just one, but of course we had to start 
        somewhere. And the origin speaks to exactly the type 
        of problem we will solve through public awareness. 
        Afterall, we are the voice behind those we elect, and 
        when we elect corrupt or inept individuals, we have an 
        obligation to fix the mistake.
      </div>
      <div>
        Here's the story:
      </div>
      <div>
        &emsp;An anonymous individual goes to court on a minor charge. 
        As the charge is minor, the trial is held at a justice of the 
        peace. The individual chooses to represent themselves, because
        they're innocent and it's obvious and they can't lose. Except
        for a few corrupt acts. 
      </div>
      <ul>
        <li>
          First, a potential juror was not dismissed from jury 
          selection after admitting that they recognized the 
          prosecutor. This juror was not dismissed, but rather
          was selected to the jury. There were over 20 potential
          jurors. <span>The correct action on the judges part would have
          been to dismiss said juror. The defendent was not given the
          opportunity to strike for cause the juror in question despite
          the request.</span>
        </li>
        <li>
          Second, the Justice of the Peace on the case, refused to
          acknowledge a witness committing clear perjury. Essentially
          what this means is that the witness told a clear lie when
          telling two contradictory facts to support their claim. 
          <span>The correct action would have been to, at very least,
          reprimand the states witness, and advise the jury to
          ignore the testimony given, especially where the clear 
          contradiction occurred.</span>
        </li>
        <li>
          Lastly, upon giving closing arguments, the prosecutor 
          waived the right to give one. However, after the closing
          argument was given by the defendent, the prosecutor gave
          a rebuttal, in which he stated that the defendent had 
          admitted guilt to them, stating "[They] told you [They]
          were guilty". However the defendent gave no testimony, and
          therefore told the jury nothing at all. <span>The correct
          action would have been to correct the record and advise
          the jury not to take the rebuttal into consideration,
          given the grave violation committed by the prosecutor.
          </span>
        </li>
      </ul>
      <div>
        The defendent in this case had no option to fight the guilty
        conviction that followed. The court in this case, a Justice
        of the Peace, is not a "court of record". Which means that
        there is no court reporting or record of the trial. The
        Justice of the Peace in this case turned a blind eye to
        injustice, even condoned it. Now we cannot speculate as to 
        the nature of this injustice, be it intentional disregard
        for the law, or incompentence. All we can do is take a stand
        for the oppressed in this case. We can fight against the
        oppressor. That's where we step in.
      </div>
      <div>
        In this case the wrongly convicted defendent came to us 
        seeking justice. With no opportunity to fight on their own,
        as a lack of evidence would make an appeal next to 
        impossible, a case of he said she said, LocalTyrant.US is
        here to document said corruption. 
      </div>
      <div>
        If you have experienced corruption yourself, at the hands
        of any government official, let us know about it. Tell your
        story and become a Tyrant Slayer today. Don't sit and watch
        your oppressors get away with their corruption. Ensure that
        their lies see the light of day. Your story really does 
        make a difference. And free of worry. Your story is completely 
        anonymous.
      </div>
    </div>
  )
}

export default OriginStory