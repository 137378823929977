import React from 'react'

const ContactUs = () => {
  return (
    <div>
      <h2>ContactUs</h2>
      <div>Email: <a href="mailto=localtyrantus@gmail.com">LocalTyrantUS@gmail.com</a></div>
    </div>
  )
}

export default ContactUs