import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import{ Col , Row } from 'react-bootstrap'

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Col md={{span:2, offset:10}}>
      <button className='auth-button justify-content-end' onClick={() => loginWithRedirect()}>
        Log In
      </button>
    </Col>
  )
};

export default LoginButton;