import React, { FunctionComponent, useLayoutEffect } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { IFilterAndSortProps } from '../Common/Interfaces'
import '../App.css'

const FilterAndSort: FunctionComponent<IFilterAndSortProps> = ({currentPage, orderBy, filterBy, orderDir, pageSize, setOrderBy, setFilterBy, setOrderDir, setPageSize}) => {
  useLayoutEffect(() => {
    let sortDirDown = document.getElementById('sort-dir-btn-down')
    let sortDirUp = document.getElementById('sort-dir-btn-up')
    sortDirDown?.classList.remove('btn-primary')
    sortDirUp?.classList.remove('btn-primary')
  })
  return (
    <Form className='filter-sort-style'>
      <Row className='black'>
        {currentPage ===  'listings' && <Col className='row'>
          View:
          <div 
            className={filterBy === 'person' ? 'link coral' : 'link'}
            onClick={() => {
              if(pageSize === 1){
                setPageSize(5)
              }
              setFilterBy('person')}}
          >PEOPLE</div>
          <div 
            className={filterBy === 'organization' ? 'link coral' : 'link'}
            onClick={() => {
              if(pageSize === 1){
                setPageSize(5)
              }
              setFilterBy('organization')}}
          >ORGANIZATIONS</div>
          <div 
            className={filterBy === 'location' ? 'link coral' : 'link'}
            onClick={() => {
              if(pageSize === 1){
                setPageSize(5)
              }
              setFilterBy('location')}}
          >GOVERNMENTS</div>
        </Col>}
        {currentPage === 'articles' && <Col className='row'>
          View:
          <div 
            className={filterBy === 'person' ? 'link coral' : 'link'}
            onClick={() => setFilterBy('economy')}
          >ECONOMY</div>
          <div 
            className={filterBy === 'organization' ? 'link coral' : 'link'}
            onClick={() => setFilterBy('justice')}
          >JUSTICE</div>
          <div 
            className={filterBy === 'location' ? 'link coral' : 'link'}
            onClick={() => setFilterBy('elections')}
          >ELECTIONS</div>
        </Col>}
        <Col>
          <Form.Label className='sort-label'>Sort: </Form.Label>
          <Form.Select value={orderBy} onChange={(e) =>setOrderBy(e.target.value)}>
            <option value='apporved_on'>Date Published</option>
            <option value='next_election'>Date of Next Election</option>
          </Form.Select>
          {
            orderDir === 'DESC' ? 
              <Button id='sort-dir-btn-down' className='sort-dir-btn' onClick={() => setOrderDir('ASC')}><div className='sort-dir-icon'>&#9660;</div></Button> : 
              <Button id='sort-dir-btn-up' className='sort-dir-btn' onClick={() => setOrderDir('DESC')}><div className='sort-dir-icon'>&#9650;</div></Button>
            }
        </Col>
      </Row>
    </Form>
  )
}

export default FilterAndSort