import React, { useEffect, useState, FunctionComponent } from 'react'
import { Form } from 'react-bootstrap'
import AdminList from './AdminList';
import AdminLogs from './AdminLogs';

interface IAdminProps {
  awaiting: any;
  approved: any; 
  status: string; 
  setStatus: (status: string) => void;
  setId: (id: string) => void;
  setCurrentPage: (page: string) => void;
}

const Admin: FunctionComponent<IAdminProps> = ({awaiting, approved, status, setStatus, setId, setCurrentPage}) => {
  
  const deleteEntry = (id: string) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/subjects/${id}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    }).then((res: any) => {
      if(res.status === 200){
        alert('Deleted article and subject.')
      }
      else if(res.status === 204){
        alert('Deleted subject.')
      }
    }
    ).then((response: any) => {
      console.log(response)
    }).catch(err => alert(err.message))
  }
  const items = status === 'awaiting' ? awaiting : approved;

  return (
    <div>
      <Form>
        <Form.Select value={status} onChange={(e)=>setStatus(e.target.value)}>
          <option value='awaiting'>Awaiting</option>
          <option value='approved'>Approved</option>
          <option value='failed'>Failed</option>
        </Form.Select>
        {status === 'failed' ? <AdminLogs /> : <AdminList items={items} setId={setId} setCurrentPage={setCurrentPage} deleteEntry = {deleteEntry} />}
      </Form>
    </div>
  )
}

export default Admin