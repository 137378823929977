import React, {FunctionComponent} from 'react';
import { Form } from 'react-bootstrap';
import { statesList } from '../Common/constants';
import '../App.css'

interface SubjectFormProps {
  submitObject: any;
  setSubmitObject: (field: any) => void;
  submit: () => void;
  setSubPage: (val: string) => void;
}

const SubjectForm: FunctionComponent<SubjectFormProps> = ({submitObject, setSubmitObject, submit, setSubPage}) => {
  return (
    <Form>
      <Form.Group>
        <Form.Label>Person Place or Thing:</Form.Label>
        <Form.Select 
          value={submitObject.entity_type}
          onChange={(e) => setSubmitObject({...submitObject, entity_type: e.target.value})}
        >
          <option>Select type of entity: </option>
          <option value="person">Person</option>
          <option value="location">Government</option>
          <option value="organization">Organization</option>
        </Form.Select>
      </Form.Group>
      {submitObject.entity_type &&
        <>
          <Form.Group>
            <Form.Label>Location:</Form.Label>
            <Form.Control 
              value={submitObject.loc} 
              onChange={(e) => setSubmitObject({...submitObject, loc: e.target.value})}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>State: </Form.Label>
            <Form.Select 
              value={submitObject.state_name} 
              onChange={(e) => setSubmitObject({...submitObject, state_name: e.target.value})}
            >
              <option>Select State </option>
              {statesList.map((state: string, i: number) => <option key={i} value={state}>{state}</option>)}
            </Form.Select>
          </Form.Group>

        {submitObject.state_name && !['Rhode Island', 'Connecticut'].includes(submitObject.state_name) &&
          <Form.Group>
            <Form.Label>
              {submitObject.state_name === 'Alaska' ? 
                'Borough' : 
                  submitObject.state_name === 'Louisiana' ? 
                  'Parish' : 
                  'County'
              }:</Form.Label>
            <Form.Control 
              onChange={(e) => setSubmitObject({...submitObject, state_sub: e.target.value})}
            />
          </Form.Group>
        }
        { submitObject.state_sub && 
          <Form.Group>
            <Form.Label>
              City or Town Name:
            </Form.Label>
            <Form.Control 
              onChange={(e) => setSubmitObject({...submitObject, municipality: e.target.value})}
            />
          </Form.Group>
        }
        {(submitObject.state_sub || submitObject.municipality)&&<Form.Group>
          <Form.Label>Population of {submitObject.municipality || submitObject.state_sub}:</Form.Label>
          <Form.Control 
            value={submitObject.population}
            onChange={(e) => {
              const pop = Number(e.target.value)

              setSubmitObject({...submitObject, population: isNaN(pop) ? 0 : pop})}
            }
          />
        </Form.Group>}
        { submitObject.entity_type &&
          <>
            <Form.Group>
              <Form.Label>
                Name of {submitObject.entity_type[0].toUpperCase() + 
                submitObject.entity_type.substring(1)}:
              </Form.Label>
              <Form.Control 
                onChange={(e) => setSubmitObject({...submitObject, subject: e.target.value})}
              />
            </Form.Group>
            <Form.Group>
            <Form.Label>Next Election:</Form.Label>
            <Form.Control
              onChange={(e) => setSubmitObject({...submitObject, next_election: new Date(e.target.value)})}
            />
          </Form.Group>
          </>
        }
        { submitObject.entity_type === 'person' &&
          <>
            <Form.Group>
              <Form.Label>Current Role: </Form.Label>
              <Form.Control 
                onChange={(e) => setSubmitObject({...submitObject, curr_role: e.target.value})}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Past Role and/or Education:</Form.Label>
              <Form.Control 
                onChange={(e) => setSubmitObject({...submitObject, background: e.target.value})}
              />
            </Form.Group>
          </>
        }
        <Form.Group>
          <Form.Label>If you're linking to an Article, copy/paste the link here:</Form.Label>
          <Form.Control 
            onChange={(e) => setSubmitObject({...submitObject, url: e.target.value})}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>If you have an image to link, copy/paste the link here:</Form.Label>
          <Form.Control 
            onChange={(e) => setSubmitObject({...submitObject, image: e.target.value})}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{submitObject.url ? 'Summarize the article below: ' : 'Please type out the key events below: '}</Form.Label>
          <Form.Control 
            as='textarea' rows ={5} onChange={(e) => setSubmitObject({...submitObject, events: e.target.value})}
          />
        </Form.Group> 
      </>
    }
    {submitObject.entity_type === 'person' && 
    <Form.Group>
      <Form.Label>Is this person qualified for their role?</Form.Label>
      <Form.Select 
        value={submitObject.qualified}
        onChange={(e) => setSubmitObject({...submitObject, qualified: e.target.value})}
      >
        <option value={'Unsure'}>Yes, No or Unsure: </option>
        <option value={'No'}>No</option>
        <option value={'Yes'}>Yes</option>
        <option value={'Unsure'}>Unsure</option>
      </Form.Select>
    </Form.Group>}
    <div className='submit-button-container'>
      {submitObject.url
        ? <button className='nav-link' onClick={() => submit()}>Submit</button> 
        : <button className='nav-link' onClick={() => setSubPage('article')}>Next</button>
      }
    </div>
  </Form>
  )
}

export default SubjectForm