
const pageReset = ( 
  page: string,     
  setSearchBarInput: (val: string) => void,
  setPage: (val: number) => void,
  setOrderBy: (val: string) => void,
  setFilterBy: (val: string) => void,
  setPageSize: (val: number) => void,
  setOrderDir: (val: string) => void
) => {
  if(['listings','tyrants','daily-tyrant'].includes(page)){
    setSearchBarInput('')
    setPage(1)
    setOrderBy('approved_on')
    setFilterBy('person')
    setPageSize(5)
    setOrderDir('DESC')
  }
  else {
    setSearchBarInput('')
    setPage(1)
    setOrderBy('published_on')
    setFilterBy('justice')
    setPageSize(5)
    setOrderDir('DESC')
  }
}

export default pageReset