import React, { FunctionComponent, useEffect, useState } from 'react';
import { getArticle } from '../API/getArticle';
import { IArticleObject } from '../Common/Interfaces';

interface ArticleProps {
  article: IArticleObject,
  approvedOn: Date,
  approvedBy: string,
  image: string,
  tyrant: string,
  id: string
}

const Article: FunctionComponent<ArticleProps> = (
  {
    article,
    approvedOn, 
    approvedBy, 
    image, 
    tyrant, 
    id
  }
) => {
  console.log('article is: ',article)
  const {title, subject_id, body} = article
  console.log('body is: ',body)
  const imageProcessor = image[0] === '.' ? require(`${image}`) : image
  {return tyrant?
  (
    <div className='article-container'>
      <br/>
      <div>Subject: <span className='tyrant-name bold orangered'>{tyrant}</span></div>
      <br/>
      <div><img src={imageProcessor} alt='image'/></div>
      <div className='tyrant-name bold orangered'>{title}</div>
      <div>Published on: {approvedOn.toLocaleString().split('T')[0]}</div>
      <div>By: {approvedBy ? approvedBy : "Anonymous"}</div>
      <div className='paragraph'>{`${body?.split('CHAR(10)').join('\n')}`}</div>
    </div>
  ):
  (
    <div className='article-container'>
      <br/>
      {/* {tyrant && <div>Subject: <span className='tyrant-name bold orangered'>{tyrant}</span></div>} */}
      <br/>
      {article.image&& <div><img src={imageProcessor} alt='image'/></div>}
      <div className='tyrant-name bold orangered'>{title}</div>
      <div>Published on: {article?.published_on?.toLocaleString().split('T')[0]}</div>
      <div>By: {article.author ? article.author : "Anonymous"}</div>
      <div className='paragraph'>{`${body?.split('CHAR(10)').join('\n')}`}</div>
    </div>
  )}
}

export default Article



