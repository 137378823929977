import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { statesList } from './Common/constants'
import ArticleForm from './Forms/ArticleForm'
import SubjectForm from './Forms/SubjectForm'
import { ISubmitObject, IArticleObject } from './Common/Interfaces'

const initialSubjectState: ISubmitObject = {
  email: undefined,
  given_name: undefined,
  family_name: undefined,
  entity_type: undefined,
  state_name: undefined,
  subject: undefined,
  state_sub: undefined,
  municipality: undefined,
  image: undefined,
  url: undefined,
  next_election: undefined,
  curr_role:undefined,
  events: undefined,
  population: undefined,
  loc: undefined,
  background: undefined,
  qualified: undefined
}

const initialArticleState: IArticleObject = {
  body: undefined,
  title: undefined
}

interface SubmitProps {
  isAuthenticated: boolean | undefined;
  userEmail: any;
  userFirst: any;
  userLast: any;
}
const Submit = ({isAuthenticated, userEmail, userFirst, userLast}: SubmitProps) => {
  const [ subPage, setSubPage ] = useState('subject')
  const [ submitObject, setSubmitObject ] = useState({...initialSubjectState})
  const [ articleObject, setArticleObject ] = useState({...initialArticleState})
  submitObject['email'] = userEmail;
  submitObject['given_name'] = userFirst;
  submitObject['family_name'] = userLast;

  const submit = () => {
    Object.keys(submitObject).forEach((item) => {
      if(submitObject[item] === undefined){
        delete submitObject[item]
      }})
    if(isNaN(Number(submitObject['next_election']))){
      delete submitObject['next_election']
    }
    if(articleObject.title && articleObject.body){
      submitObject.url = articleObject.title
    } 
    fetch(`${process.env.REACT_APP_BASE_URL}/subject`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({...submitObject, ...articleObject})
      }
    ).then((res: any) => {
      if(res.ok){
        window.location.reload()
      }
    }).catch(err => alert(err.message))
  }
  return (
    <div>
      {subPage === 'subject' && 
        <SubjectForm 
          submitObject={submitObject} 
          setSubmitObject={setSubmitObject} 
          submit={submit} 
          setSubPage={setSubPage}
        />
      }
      {subPage === 'article' && 
        <ArticleForm 
          articleObject={articleObject} 
          setArticleObject={setArticleObject} 
          submit={submit} 
          setSubPage={setSubPage} 
        />
      }
    </div>
  )
}

export default Submit