import React, { useEffect, useState } from 'react'

const AdminLogs = () => {
  const [items, setItems] = useState<any[]>([])
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/failed`).then(res => res.json()).then(items => setItems(items))
  }, [])
  return (
    <div>
      {items.map(item => <div>{item.request}</div>)}
    </div>
  )
}

export default AdminLogs