import React, {useState, useEffect, FunctionComponent} from 'react'
import { ISubjectAdminObject, IArticleObject } from '../Common/Interfaces'
import { Button, Form } from 'react-bootstrap'
import '../App.css'

interface IAdminDetailsProps {
  id: any;
  items: any;
  setId: (id: string) => void;
  setCurrentPage: (page: string) => void;
}
const initialArticleState: IArticleObject = {
  body: undefined,
  title: undefined,
}
const initialSubjectState: ISubjectAdminObject = {
  entity_type: undefined,
  state_name: undefined,
  subject: undefined,
  state_sub: undefined,
  municipality: undefined,
  image: undefined,
  url: undefined,
  next_election: undefined,
  curr_role:undefined,
  events: undefined,
  population: undefined,
  loc: undefined,
  background: undefined,
  qualified: undefined,
  approved: undefined,
  approved_by: undefined
}

const AdminDetails: FunctionComponent<IAdminDetailsProps> = ({id, items, setId, setCurrentPage}) => {
  const [object, setObject] = useState(initialSubjectState)
  const [article, setArticle] = useState(initialArticleState)
  const [payload, setPayload] = useState({...initialArticleState, ...initialSubjectState})
  const [itemIds, setItemIds] = useState<string[]>([])

  useEffect(() => {
    const itemIdsMap = items.map((item: any) => item.id)
    setItemIds([...itemIdsMap])
    const item = items.filter((item: any) => item.id === id)[0]
    setObject({...item})
  },[])
  useEffect(() => {
    if(object.url !== undefined && object.url?.substring(0,4) !== 'http'){
      fetch(`${process.env.REACT_APP_BASE_URL}/admin/articles/${id}`
    ).then(
        (res: any) => res.json()).then((response: any)=>
        {
          setArticle({body: response[0].body, title: response[0].title});
        }).catch((err: any) => console.error(err)
    )}
  },[object])
  const submit = () =>{
    fetch(`${process.env.REACT_APP_BASE_URL}/admin/subjects/${id}`,
      {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      }
      ).then(
        (res: any) => {
          if(res.ok){
            alert(res.json())
          }
        }).catch((err: any) => console.error(err))
  }
  const stringKeys = [
    'subject',
    'image',
    'entity_type',
    'url',
    'curr_role',
    'loc',
    'events',
    'background',
    'municipality',
    'municipality_type',
    'state_sub',
    'state_sub_type'
  ]

  const handleIdChange = (action: string) => {
    if(items.length > 1){
      if(action === 'prev-id'){
        const prevItem = items[itemIds.indexOf(id) - 1]
        console.log(prevItem)
        if(!prevItem){
          setId(items[items.length-1]['id'])
          setObject({...items[items.length-1]})
        } else {
          setId(prevItem['id'])
          setObject({...prevItem})
        }
      }
      else if(action === 'next-id'){
        const nextItem = items[itemIds.indexOf(id) + 1]
        console.log(nextItem)
        if(!nextItem){
          setId(items[0]['id'])
          setObject({...items[0]})
        } else{
          setId(nextItem['id'])
          setObject({...nextItem})
        }
      }
    }
  }
  return (
    <div>
      <div className='row-between'>
        <div 
          id='prev-id' 
          className='admin-subject-change-btn'
          onClick={(e) => handleIdChange(e.currentTarget.id)}
        >
          {'< Previous'}
        </div>
        <div 
          id='next-id'
          className='admin-subject-change-btn'
          onClick={(e) => handleIdChange(e.currentTarget.id)}
        >{'Next >'}</div>
      </div>
      <Form>
        {stringKeys.map(
          (key:any) => {
            return(
              <Form.Group>
                <Form.Label>{key}:</Form.Label>
                <Form.Control 
                  onChange={(e: any) => {
                    setObject({...object, [key]: e.target.value})
                    setPayload({...payload, [key]: e.target.value})
                  }}
                  value={object[key] === null ? "" : String(object[key])!}
                />
              </Form.Group>
            )
          })
        }
        <Form.Group>
          <Form.Label>Next Election:</Form.Label>
          <Form.Control 
            onChange={(e: any) => {
              setObject({...object, ['next_election']: e.target.value})
              setPayload({...payload, ['next_election']: e.target.value})
            }}
            value={String(object['next_election']).split('T')[0]!}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Qualified</Form.Label>
          <Form.Select 
            value={object['qualified']} 
            onChange={(e) => {
              setObject({...object, ['qualified']: e.target.value})
              setPayload({...payload, ['qualified']: e.target.value})
            }}
            >
            <option value={'Unsure'}>Yes, No or Unsure: </option>
            <option value={'No'}>No</option>
            <option value={'Yes'}>Yes</option>
            <option value={'Unsure'}>Unsure</option>
          </Form.Select>
        </Form.Group>
        {article?.title &&
          <Form.Group>
            <div>
              <div>Title:</div>
              <input 
                onChange={(e: any) => {
                  setArticle({...article, title: e.target.value})
                  setPayload({...payload, title: e.target.value})
                  }
                }
                value={article.title}
              />
            </div>
          </Form.Group>
        }
        {article?.title &&
          <Form.Group>
            <div>
              <div>Body:</div>
              <textarea onChange={(e:any) => {
                setArticle({...article, body: e.target.value})
                setPayload({...payload, body: e.target.value})
              }}
                value={article.body}  
              />
            </div>
          </Form.Group>
        }
        <Form.Group>
          <Form.Label>Approve</Form.Label>
          {object['approved'] &&
            <Form.Check 
              type='checkbox' 
              checked
              onChange={(e) => {
                setObject({...object, ['approved']: false})
                setPayload({...payload, ['approved']: false})
            }}
            /> }
          {!object['approved'] &&
            <Form.Check 
              type='checkbox' 
              onChange={(e) => {
                setObject({...object, ['approved']: true})
                setPayload({...payload, ['approved']: true})
              }}
            />
          }
        </Form.Group>
      </Form>
      <Button className='admin-submit' onClick={() => submit()}>Submit</Button>
    </div>
  )
}

export default AdminDetails;