import React, {FunctionComponent} from 'react'
import { Form } from 'react-bootstrap'

interface ArticleFormProps {
  articleObject: any;
  setArticleObject: (field: any) => void;
  submit: () => void;
  setSubPage: (val: string) => void;
}

const ArticleForm: FunctionComponent<ArticleFormProps> = ({articleObject, setArticleObject, submit, setSubPage}) => {
  return (
    <Form>
      <Form.Group>
        <Form.Label>Provide a title:</Form.Label>
        <Form.Control 
          onChange={(e) => setArticleObject({...articleObject, title: e.target.value})}
        />
      </Form.Group> 
      <Form.Group>
        <Form.Label>Provide your official statement of events below:</Form.Label>
        <Form.Control 
          as='textarea' rows ={5} onChange={(e) => setArticleObject({...articleObject, body: e.target.value})}
        />
      </Form.Group> 
      <button className='nav-link' onClick={() => submit()}>Submit</button> 
</Form>
  )
}

export default ArticleForm