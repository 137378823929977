import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Col } from "react-bootstrap";

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <Col md={{span:2, offset:10}}>
      <button className='auth-button justify-content-end' onClick={() => logout({ returnTo: window.location.origin })}>
        Log Out
      </button>
    </Col>
  );
};

export default LogoutButton;