import React, { FunctionComponent } from 'react'
import Listings from '../Listings'
import FilterAndSort from '../Navigation/FilterAndSort'

interface IArticlesInterface {
  contentIsLoading: boolean;
  listings: any;
  setCurrentPage: (val: string) => void;
  setSubject: (val: string) => void;
  setApprovedOn: (val: Date) => void;
  setApprovedBy: (val: string) => void;
  setImage: (val: string) => void;
  setId: (val: string) => void;
}
/// Replace Listings here with a new component for articles
const Articles: FunctionComponent<IArticlesInterface> = ({
    contentIsLoading, 
    listings, 
    setCurrentPage, 
    setSubject, 
    setApprovedOn, 
    setApprovedBy,
    setImage,
    setId
  }) => {
  return (
    <div>
      <Listings 
        contentIsLoading = {contentIsLoading}
        listings = {listings} 
        setContentIsLoading = {() => {}}
        setCurrentPage = {setCurrentPage} 
        setSubject = {()=>{}}
        setApprovedOn = {()=>{}}
        setApprovedBy = {()=>{}}
        setImage = {()=>{}}
        setId = {setId}
      />
    </div>
  )
}

export default Articles