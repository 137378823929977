import React, { FunctionComponent, useEffect } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { BsImage } from 'react-icons/bs';
import { getArticle } from './API/getArticle';
import { GOVERNMENT_FIELDS, LOCATION_FIELDS, PERSON_FIELDS, ARTICLES_FIELDS } from './Common/constants';
import { returnSubHeader } from './fieldNamesService';

interface ListingsProps {
  contentIsLoading: boolean;
  listings: any[];
  setContentIsLoading: (val: boolean) => void;
  setCurrentPage: (page: string) => void;
  setSubject: (subject: string) => void;
  setApprovedOn: (approvedOn: Date) => void;
  setApprovedBy: (approvedBy: string) => void;
  setImage: (article: string) => void;
  setId: (id: string) => void;
}
const Listings: FunctionComponent<ListingsProps> = ({contentIsLoading, listings, setContentIsLoading, setCurrentPage, setSubject, setApprovedOn, setApprovedBy, setImage, setId}) => {
  const openArticle = async (item: any) => {
    if(item?.url?.substring(0,4) === 'http'){
      const url = String(item.url)
      window.location.replace(url)
    }
    else {
      setImage(item.image || '')
      setId(item.id)
      setContentIsLoading(true)
      setCurrentPage('article')
    }
  }
  return (
    <div id='main_table'>
      {listings.length !== 0 && listings.map((field) => {
        field.next_election = field.next_election ? field.next_election.split('T')[0] : null;
        return(
          <Row key={field.id} className='main_row' onClick={() =>setSubject(field.subject)}>
            { field.image ?
              <Col lg={3}>
                <div className='img-container'>
                  <img src={field.image[0] === '.' ? require(`${field.image}`) : field.image} alt={field.subject + '.jpeg'} width={200} />
                </div>
              </Col> :
              <Col lg={3}>
                <div className='img-container'>
                  <div className='gray-box'>
                    <BsImage size='30px' color='lightcoral'/>
                  </div>
                </div>
              </Col>
            }
            <Col lg={field.entity_type ? 3 : 5}>
              <header className='sub_header'>{returnSubHeader(field.entity_type || field.genre_type)}</header>
              <div><span className='bold coral'>{field.subject || field.title}</span></div>
              {
                field.entity_type === 'person' && <>
                {Object.keys(PERSON_FIELDS).map((item) => {
                  return (
                    <div>{PERSON_FIELDS[item]}
                      <span className='bold'>{!field[item] ? 'Unsure' : field[item]}</span>
                    </div>
                  )}
                )}
                </>
              }
              {
                field.entity_type === 'location' && <>
                {Object.keys(LOCATION_FIELDS).map((item) => {
                  return (
                    <div>{LOCATION_FIELDS[item]}
                      <span className='bold'>{!field[item] ? 'Unsure' : field[item]}</span>
                    </div>
                  )}
                )}
                </>
              }
              {
                field.entity_type === 'location' && <>
                {Object.keys(GOVERNMENT_FIELDS).map((item) => {
                  return (
                    <div>{GOVERNMENT_FIELDS[item]}
                      <span className='bold'>{!field[item] ? 'Unsure' : field[item]}</span>
                    </div>
                  )}
                )}
                </>
              }
              {
                field.genre_type && <>
                {Object.keys(ARTICLES_FIELDS).map((item) => {
                  return (
                    <div>{ARTICLES_FIELDS[item]}
                      <span className='bold'>{!field[item] ? 'Unsure' : field[item]}</span>
                    </div>
                  )}
                )}
                </>
              }
            </Col>
            { field.entity_type &&
              <Col lg={4}>
                <span className='sub_header'>Events</span>
                <div>{field.events}</div>
              </Col>
            }
            <Col lg={field.entity_type ? 2 : 4}>
              <span className='sub_header'>Links</span>
              {
                <div className='overflowX'><span className='link coral overflowX' onClick={() => openArticle(field)}>{field.url || field.title}</span></div>
              }
            </Col>
          </Row>
        )})}
        {contentIsLoading && <div><div><Spinner animation='border' /></div><div>Loading...</div></div>}
        {!listings.length && !contentIsLoading && <div><h3>Welp, it looks like we've hit a snag.</h3><div>Try again later.</div></div>}
    </div>
  )
}

export default Listings