import React from 'react'

const Platform = () => {
  return (
    <div>
      &emsp;The political goals of this site are primarily to remove 
      elected officials who abuse their authority, in any capacity, 
      to reform corrupt organizations, and influence elections, in
      a non-partisan manner, toward these goals.
      <br/><br/>
      &emsp;The issues that this organization promotes in support of
      these goals are several:
      <br/><br/><br/>
      <h3>Economic.</h3>
      <br/><br/>
      &ensp;&bull;&ensp;Universal Basic Income. Employment is about
      to experience unfathomable upheaval. As technological advances
      make human labor reduntant, the labor force will largely be 
      forced into an early retirement. It would be cruel and unusual
      to force those unemployed into a hellish existence of losing
      property and livelihood. It must be replaced by a Universal 
      Basic Income, UBI. Additionally this will assist in preventing
      the exploitation of laborers, as they aren't forced to choose
      between leaving and publicising such abuses, and their
      livelihood. To be clear, a UBI isn't meant to cover any and
      all expenses, but as the name implies, it should cover the
      basics, with the decisions that implies left to the individual.
      <br/><br/>
      &ensp;&bull;&ensp;Public Option. We support a public option in
      healthcare. The lack of a public option is opposed by private
      institutions, through lobbying efforts, publicly claiming that
      the "competition" presented by the free market lowers prices. 
      However, this actually a false statement, as government 
      insurance is much cheaper. Some would argue it's lower in
      quality, but it is the opinion of this site that the current 
      system is pretty bad, and desparately needs the competition
      which they claim to crave, in the form of a public option.
      <br/><br/><br/>
      <h3>Legislation.</h3>
      <br/><br/>
      &ensp;&bull;&ensp;Decriminalization of Victimless Crime. 
      Currently, there are many laws which fine or imprison
      individuals for which there is no injured party. For instance,
      if an individual had three drinks and drove, they might be
      charged with stiff fines and penalties for DWI, despite that
      individual not posing any actual or present risk, based on an
      arbitrary measurement. For instance, if an individual
      had a blood alcohol content of .07, and the legal limit is
      .08, the "real" difference between two people could be that
      the one with the higher blood alcohol level is perfectly 
      capable of driving safely, while the one with a lower limit is
      not. One should either be demonstrably and obviously incapable,
      or otherwise, an injured party should be required for such
      charges to stand.
      <br/><br/>
      &ensp;&bull;&ensp;End Moral Law. No law should be passed
      pertaining to morals, as morals are not universal. All laws 
      currently policing morality should be abolished. This would
      include laws pertaining to gambling, the consumption of drugs
      and alcohol, sexuality, religion, foul language, etc. The will
      of the people should bear no weight in the freedom of 
      individuals to behave as they like, so long as there is no
      injured party. For instance, gambling should be legal, but
      rigging a game that individuals are betting on should be a 
      criminal offense, as there are injured parties, in those
      that lost their bets due to the injustice.
      <br/><br/>
      &ensp;&bull;&ensp;Criminialize Political Chicanery. 
      Superpacs, gerrymandering, barriers to voting, lobbying, etc. 
      These divisive activities cause immense harm to our nation, 
      dividing people accross party lines, rather than individual 
      issues. The fact is that money in politics is the primary issue 
      we face today. Lobbying and superpacs force politicians to work 
      on behalf of wealthy donors, rather than the populations they 
      are elected to represent.
      <br/><br/><br/>
      <h3>Elections.</h3>
      <br/><br/>
      &ensp;&bull;&ensp;Public Vote Count. In the current 
      technological environment, a public vote count would be quite
      simple. PDF's and CSV's should hold public record of elections
      every election could be contained by polling location, 
      digitally, and published to a federal site. This would enable a 
      public vote count. The facilitation of such an event would be 
      quite simple. Each voter would recieve an id number, in place
      of the "I Voted" stickers, which would show how they cast their
      votes. This is an important part of ending any real question of 
      voter fraud.
      <br/><br/>
      &ensp;&bull;&ensp;Federal Voter Recognition Software. Facial
      recognition software is currently capable of identifying a
      voter. Recognize that most people have no problem posting your 
      face all over the internet on social media, and many banks now
      authorize individuals through voice recognition, that phones
      now authenticate via, facial recognition, thumb print, etc.
      There isn't any real technological barrier to this technology 
      being used. The only real barrier at present is opposition by
      those that claim they wouldn't want the government having a 
      picture of their face. (they have a drivers license though) The
      fact is that a lack of identification should never be a
      hindrance to one's right to vote, if we do in fact, live in a
      democratic republic. In the event that a voter could not be
      verified, their photo should be catured and saved for later
      verification, if necessary.
    </div>
  )
}

export default Platform