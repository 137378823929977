import React, { SyntheticEvent, useEffect, useState } from 'react';
import './App.css';
import { Col, Container, Form, Row } from 'react-bootstrap';
import TopNav from './TopNav/TopNav';
import Listings from './Listings';
import Article from './Articles/Article';
import Submit from './Submit';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
import Profile from './Profile';
import AdminDetails from './Admin/AdminDetails';
import { useAuth0 } from '@auth0/auth0-react';
import OriginStory from './Footer/OriginStory';
import MobileTopNav from './TopNav/MobileTopNav';
import { inject } from '@vercel/analytics';
import Admin from './Admin/Admin';
import FilterAndSort from './Navigation/FilterAndSort';
import Platform from './Footer/Platform';
import ContactUs from './Footer/ContactUs';
import Articles from './Articles/Articles';
import pageReset from './Common/pageReset';
import { getArticle } from './API/getArticle';
import { IArticleObject } from './Common/Interfaces';

function App() {
  inject();
  const { user, isAuthenticated, isLoading } = useAuth0()
  const [ contentIsLoading, setContentIsLoading] = useState(true)
  const [ listings, setListings ] = useState<any[]>([])
  const [ article, setArticle ] = useState({} as IArticleObject)
  const [ showSearchBar, setShowSearchBar ] = useState(false)
  const [ isSearching, setIsSearching ] = useState(false)
  const [ searchBarInput, setSearchBarInput ] = useState('')
  const [ currentPage, setCurrentPage ] = useState('listings')
  const [ subject, setSubject ] = useState('')
  const [ approvedOn, setApprovedOn ] = useState(new Date)
  const [ approvedBy, setApprovedBy ] = useState('')
  const [ image, setImage ] = useState('')
  const [ id, setId ] = useState('')
  const [ awaiting, setAwaiting ] = useState([])
  const [ status, setStatus ] = useState('awaiting')
  const [ page, setPage ] = useState<number>(1)
  const [ orderBy, setOrderBy] = useState<string>('')
  const [ filterBy, setFilterBy ] = useState<string>('')
  const [ pageSize, setPageSize ] = useState<number>(1)
  const [ orderDir, setOrderDir ] = useState<string>('DESC')
  const [ error, setError ] = useState<unknown>(false)

  useEffect(() => {
    if(currentPage === 'admin'){
      fetch(`${process.env.REACT_APP_BASE_URL}/admin/${status}`).then((res: any) => 
        res.json()
      ).then((response: any) => {
        setAwaiting(response)
      })
    } 
  },[currentPage])

  const items = status === 'awaiting' ? awaiting : listings;

  const onPageClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if(e.target.id === 'main_search-box'){
      setIsSearching(true)
    } else updateIsSearching(e)
  }
  const updateIsSearching = (e: SyntheticEvent) => {
    e.stopPropagation()
    e.preventDefault()
    if(!searchBarInput.length){
      setIsSearching(false)
    }
    else setIsSearching(true)
  }

  const currentPageRoute = () => {
    switch(currentPage){
      case 'tyrants':
        return 'subjects'
      case 'daily-tyrant':
        return 'subjects'
      case 'listings':
        return 'subjects'
      case 'articles':
        return 'content'
      default:
        return currentPage
    }
  }

  const isMobile = navigator.userAgent.toLowerCase().includes('mobile');
  const fetchStr = `${process.env.REACT_APP_BASE_URL}/${currentPageRoute()}/${page}?
    ${searchBarInput ? 'search_term='+searchBarInput + '&' : ''}
    ${filterBy ? 'filter_by=' + filterBy + '&' : ''}
    ${orderBy ? 'order_by=' + orderBy + '&' : ''}
    ${pageSize ? 'page_size=' + pageSize + '&': ''}
    ${orderDir ? 'order_dir=' + orderDir : ''}`
  useEffect(() => { 
    setContentIsLoading(true)
    if(currentPage === 'article'){
        getArticle(id).then((data) => {
          console.log(data)
          setArticle({...data})
        })
    }
    else{
      fetch(fetchStr.replace(/\s/g,'')).then(res => {
        setContentIsLoading(false)
        return res.json()
      }
      ).then(response => {
        console.log('response is: ', response)
        setListings([...response]); 
        setIsSearching(false)
      }).catch(err => {
        console.error(err); 
        setIsSearching(false)
      })
    }
  },[searchBarInput, filterBy, page, orderBy, pageSize, orderDir, currentPage])

  const resetPage = (page: string) => {
    pageReset(
      page,
      setSearchBarInput,
      setPage,
      setOrderBy,
      setFilterBy,
      setPageSize,
      setOrderDir
    )
  }
  const handlePageChange = (page='') => {
    console.log(document.querySelector('#main_search-box'))
    if(page === 'daily-tyrant'){
      resetPage(page)
      setPageSize(1)
      setCurrentPage('listings')
    }
    if(page === 'tyrants'){
      resetPage(page)
      setPageSize(5)
      setCurrentPage('listings')
    }
    if(page === 'articles'){
      resetPage(page)
      setCurrentPage('articles')
    }
    if(page === 'submit'){
      setCurrentPage('submit')
    }
    if(page === 'back'){
      switch(currentPage){
        case 'admin-detail':
          setCurrentPage('admin')
          break;
        default: setCurrentPage('listings')
      }
    }
  }
  const currentPageIsListings = ['articles','listings'].includes(currentPage)
  return (
    <div className="App" onClick = {(e: any) => onPageClick(e)}>
      <Container fluid className='container-class pt-3' >
        {user?.email === 'skyledodd1989@gmail.com' && <button onClick={() =>setCurrentPage('admin')}>Admin</button>}
        {!user?.email ? <LoginButton /> : <LogoutButton />}
        <header className='main_header'>
          <div>LocalTyrant.US</div>
        </header>
        { isMobile ?
          <MobileTopNav
            showSearchBar = {showSearchBar} 
            isSearching = {isSearching} 
            searchBarInput = {searchBarInput}
            isAuthenticated = {isAuthenticated}
            currentPageIsListings = {currentPageIsListings}
            setShowSearchBar = {setShowSearchBar} 
            setIsSearching = {setIsSearching}
            setSearchBarInput = {setSearchBarInput}
            handlePageChange = {handlePageChange}
          /> :
          <TopNav 
            showSearchBar = {showSearchBar} 
            isSearching = {isSearching} 
            searchBarInput = {searchBarInput}
            isAuthenticated = {isAuthenticated}
            currentPageIsListings = {currentPageIsListings}
            setShowSearchBar = {setShowSearchBar} 
            setIsSearching = {setIsSearching}
            setSearchBarInput = {setSearchBarInput}
            handlePageChange = {handlePageChange}
          />
        }
        {!['listings', 'articles'].includes(currentPage) ?
          <div 
            className='link back-button white' 
            onClick={()=> handlePageChange('back')}
          >
            {'< Back'}
          </div> :
          <FilterAndSort
            currentPage={currentPage}
            orderBy={orderBy}
            orderDir={orderDir}
            filterBy={filterBy}
            pageSize={pageSize}
            setOrderBy={setOrderBy}
            setOrderDir={setOrderDir}
            setFilterBy={setFilterBy}
            setPageSize={setPageSize}
          />
        }
        {currentPage === 'articles' &&
          <Articles 
            contentIsLoading={contentIsLoading}
            listings={listings} 
            setCurrentPage={setCurrentPage} 
            setSubject={setSubject} 
            setApprovedOn={setApprovedOn} 
            setApprovedBy={setApprovedBy} 
            setImage={setImage} 
            setId={setId}
          />
        }
        {currentPage === 'listings' && 
          <Listings 
            contentIsLoading={contentIsLoading}
            listings={listings} 
            setContentIsLoading={setContentIsLoading}
            setCurrentPage={setCurrentPage} 
            setSubject={setSubject} 
            setApprovedOn={setApprovedOn} 
            setApprovedBy={setApprovedBy} 
            setImage={setImage} 
            setId={setId}
          />}
        {currentPage === 'article' && 
          <Article 
            article={article}
            tyrant={subject}
            approvedOn={approvedOn} 
            approvedBy={approvedBy} 
            image={image}
            id={id}
          />
        }
        {currentPage === 'submit' && <Submit isAuthenticated={isAuthenticated} userEmail={user?.email} userFirst={user?.given_name} userLast={user?.family_name}/>}
        {currentPage === 'profile' && <Profile user={user} isAuthenticated={isAuthenticated} isLoading={isLoading} />}
        {currentPage === 'origin' && <OriginStory />}
        {currentPage === 'platform' && <Platform />}
        {currentPage === 'contact' && <ContactUs />}
        {user?.email === 'skyledodd1989@gmail.com' && currentPage === 'admin' && <Admin awaiting={awaiting} approved={listings} status={status} setStatus={setStatus} setId={setId} setCurrentPage={setCurrentPage}/>}
        {currentPage === 'admin-details' && <AdminDetails id={id} items={items} setId={setId} setCurrentPage={setCurrentPage}/>}
        {currentPage === 'listings' &&
          <Row>
            {page !== 1 ? <Col>{'Previous ' + pageSize + ' entries'}</Col> : <Col></Col>}
            <Col>
              <span>Number of entries per page: </span>
              <Form>
                <Form.Select 
                  onChange={(e: any)=>setPageSize(e.target.value)}>
                    {[1,5,10,20].map((num:number)=> num === 1 ?
                      <option value={num} disabled>{num}</option> :
                      <option value={num}>{num}</option>)}
                </Form.Select></Form></Col>
            {pageSize !== 1 ?<Col>{'Next ' + pageSize + ' entries'}</Col> : <Col></Col>}
          </Row>
        }
        {error !== false && <Row>
          <div>An error has occurred. Please reload.</div>
          <input onClick={() => window.location.reload()} value='Reload'/>
          </Row>}
        <Row className='black-bg mt-2'>
          <Col className='m-2 p-2'><span className='footer-item' onClick={() => setCurrentPage('origin')}>Origin Story</span></Col>
          <Col className='m-2 p-2'><span className=''>Terms of Service</span></Col>
          <Col className='m-2 p-2'><span className='footer-item' onClick={() => setCurrentPage('contact')}>Contact Us</span></Col>
          <Col className='m-2 p-2'><span className='footer-item' onClick={() => setCurrentPage('platform')}>Platform</span></Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
