import { useAuth0 } from '@auth0/auth0-react';
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Container, InputGroup, Nav, Navbar } from 'react-bootstrap'
import { BsFillXSquareFill, BsSearch } from 'react-icons/bs'
import SearchBox from '../Navigation/SearchBox';

interface TopNavProps {
  showSearchBar: boolean;
  isSearching: boolean;
  searchBarInput: string;
  isAuthenticated: any;
  currentPageIsListings: boolean;
  setShowSearchBar: (value: boolean) => void;
  setIsSearching: (value: boolean) => void;
  setSearchBarInput: (value: string) => void;
  handlePageChange: (value: string) => void;
}
const TopNav: FunctionComponent<TopNavProps> = ({
  showSearchBar, 
  isSearching, 
  searchBarInput, 
  isAuthenticated,
  currentPageIsListings,
  setShowSearchBar, 
  setIsSearching, 
  setSearchBarInput,
  handlePageChange
}) => {
  const [ tempVal, setTempVal ] = useState('')
  const [ lastTempVal, setLastTempVal ] = useState('')

  useEffect(() => {
    if(tempVal === lastTempVal){
      setSearchBarInput(tempVal)
      setIsSearching(false)
    } else setIsSearching(true)
  },[lastTempVal])
  const updateSearch = (val: string) => {
    setTempVal(val)
    
    setTimeout(() => {
      setLastTempVal(val)
    },2000)
  }
  const { loginWithRedirect } = useAuth0()
  return (
    <Navbar id='nav-bar_ribbon'>
      <Container>
        <Navbar.Brand id='daily-tyrant' className='link' onClick={() => handlePageChange('daily-tyrant')}>Tyrant o' the Day</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {currentPageIsListings &&
              <InputGroup onMouseEnter={()=> setShowSearchBar(true)} onMouseLeave={()=>setShowSearchBar(false)}>
                {(showSearchBar || isSearching) ? 
                  <>
                    <SearchBox
                      searchBarInput={tempVal}
                      updateSearch={updateSearch}
                      isSearching={isSearching}
                    />
                    <BsFillXSquareFill 
                      className='main_search-icon'
                      onClick={() =>{
                        setIsSearching(false)
                        setShowSearchBar(false)
                        setSearchBarInput('')
                      }}
                    /> 
                  </> :
                  <BsSearch 
                    className='main_search-icon'
                  />
                }
              </InputGroup> 
            }
          </Nav>
        </Navbar.Collapse>
        <div className='justify-content-end'>
          <Nav.Link 
            id='tyrants'
            className='link' 
            onClick={() => handlePageChange('tyrants')}
          >
            Tyrants
          </Nav.Link>
          <Nav.Link 
            id='articles'
            className='link' 
            onClick={() => handlePageChange('articles')}
          >
            Tyrant Toppling Wisdom
          </Nav.Link>
          <Nav.Link 
            className='tell-your-story' 
            onClick={() => {isAuthenticated || process.env.REACT_APP_ENV === 'dev' ? 
              handlePageChange('submit') : loginWithRedirect()}}
          >
            Tell your story
          </Nav.Link>
        </div>
      </Container>
    </Navbar>
  )
}

export default TopNav