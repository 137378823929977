export const returnSubHeader = (entityType) => {
  switch(entityType){
    case 'person':
      return 'Tyrant'
    case 'location':
      return 'Tyrannical Government'
    case 'economy':
      return 'Title'
    case 'justice':
      return 'Title'
    case 'election':
      return 'Title'
  }
  if(entityType === 'person'){
    return 'Tyrant'
  } else if(entityType === 'location'){
    return 'Tyranical Government'
  }

}