import { useAuth0 } from '@auth0/auth0-react';
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Container, InputGroup, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { BsFillXSquareFill, BsSearch } from 'react-icons/bs'
import SearchBox from '../Navigation/SearchBox';

interface TopNavProps {
  showSearchBar: boolean;
  isSearching: boolean;
  searchBarInput: string;
  isAuthenticated: boolean;
  currentPageIsListings: boolean;
  setShowSearchBar: (value: boolean) => void;
  setIsSearching: (value: boolean) => void;
  setSearchBarInput: (value: string) => void;
  handlePageChange: (value: string) => void;
}
const MobileTopNav: FunctionComponent<TopNavProps> = ({
  showSearchBar, 
  isSearching, 
  searchBarInput, 
  isAuthenticated,
  currentPageIsListings,
  setShowSearchBar, 
  setIsSearching, 
  setSearchBarInput,
  handlePageChange
}) => {
  const [ tempVal, setTempVal ] = useState('')
  const [ lastTempVal, setLastTempVal ] = useState('')
  useEffect(() => {
    if(tempVal === lastTempVal){
      setSearchBarInput(tempVal)
      setIsSearching(false)
    } else setIsSearching(true)

  },[lastTempVal])
  const updateSearch = (val: string) => {
    setTempVal(val)
    
    setTimeout(() => {
      setLastTempVal(val)
    },2000)
  }
  const { loginWithRedirect } = useAuth0()
  return (
    <>
      <Navbar id='nav-bar_ribbon'>
        <Container >
          <NavDropdown title="Menu" id="basic-nav-dropdown">
            <NavDropdown.Item 
              className='tell-your-story justify-content-end' 
              onClick={() => {isAuthenticated ? handlePageChange('submit') : loginWithRedirect()}}
            >
              Tell your story
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item 
              id='daily-tyrant'
              className='link' 
              onClick={() => handlePageChange('daily-tyrant')}
            >
              Tyrant o' the day
            </NavDropdown.Item>
            <NavDropdown.Item 
              id='tyrants'
              className='mobile-link' 
              onClick={() => handlePageChange('tyrants')}
            >
              Tyrants
            </NavDropdown.Item>
            <NavDropdown.Item 
              id='articles'
              className='mobile-link' 
              onClick={() => handlePageChange('articles')}
            >
              Tyrant Toppling Wisdom
            </NavDropdown.Item>

          </NavDropdown>
        </Container>
      </Navbar>
      {
        currentPageIsListings &&
          <SearchBox searchBarInput={tempVal} updateSearch={updateSearch} isSearching={isSearching}/>
        }
    </>
  )
}

export default MobileTopNav