export const statesList = ['Alabama','Alaska','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','Florida','Georgia','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Ohio','Oklahoma','Oregon','Pennsylvania','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virginia','Washington','West Virginia','Wisconsin','Wyoming']

interface PersonFieldsInterface {
  [key: string]: string,
  curr_role: string,
  loc: string,
  next_election: string,
  background: string,
  qualified: string
}
export const PERSON_FIELDS: PersonFieldsInterface = {
  curr_role: 'Current Role: ',
  loc: 'Location: ',
  next_election: 'Term ends: ',
  background: 'Background: ',
  qualified: 'Qualified for Position?: '
}
interface LocationFieldsInterface {
  [key: string]: string,
  population: string,
  loc: string,
  state_sub: string,
  state_name: string,
  next_election: string
}

export const LOCATION_FIELDS: LocationFieldsInterface = {
  population: 'Population: ',
  loc: 'Location: ',
  state_sub: 'County: ',
  state_name: 'State: ',
  next_election: 'Next Election: '
}

interface GovernmentFieldsInterface {
  [key: string]: string,
  population: string,
  loc: string,
  state_sub: string,
  state_name: string,
  next_election: string
}

export const GOVERNMENT_FIELDS: GovernmentFieldsInterface = {
  population: 'Population: ',
  loc: 'Location: ',
  state_sub: 'County: ',
  state_name: 'State: ',
  next_election: 'Next Election: '
}
//

interface ArticlesFieldsInterface {
  [key: string]: string,
  summary: string
}

export const ARTICLES_FIELDS: ArticlesFieldsInterface = {
  summary: 'Summary: '
}